import { digit, email, maxLength, maxNumber, numeric, NumericValueType, pattern, prop, required } from "@rxweb/reactive-form-validators";
import { SaleOpportunityViewModel } from "../opportunity-management/opportunity.model";
import { Contact, ContactContactType, ProfileContact, ProfileDetailModel } from "../profile-management/profile-detail.model";
import { SaleAccountViewModel } from "../sale-account-management/add-sale-acount.model";
import { Contact as ContactForm } from '../../../shared/components/stand-alone-component/contact/contact.model';
import { ProfileType } from "../opportunity-management/crm-opportunity-details/crm-opportunity-details.component";
export class SaleLeadsViewModel {
    @prop()
    saluation: string;
    @prop()
    firstName: string;
    @prop()
    lastName: string;
    @prop()
    @required()
    company: string;
    // @prop()
    // @digit()
    // @maxNumber({ value: 2147483647 })
    // noOfEmployee: number;
    @prop()
    department: string;

    //@pattern({ expression: { phone: /^[(]?(\d|\+)\s?(?:[\d-.x\s()]*)$/ }, message: 'This field is not valid' })
    //@required()
    @prop()
    contactPhone: string;

    @prop()
    @email()
    contactEmail: string;
    @prop()
    state: string;
    @prop()
    city: string;
    @prop()
    description: string;
    @prop()
    leadSource: string;
    @prop()
    @numeric({ acceptValue: NumericValueType.Both, allowDecimal: true, message: 'Only numberic is allowed' })
    annualRevenue: number;
    @prop()
    industry: string;
    @prop()
    website: string;
    @prop()
    typeName: string = ProfileType[ProfileType.LEADS];
    @prop()
    @numeric({ acceptValue: NumericValueType.PositiveNumber, allowDecimal: true, message: 'Only numberic is allowed' })
    @maxNumber({ value: 1000000000, message: 'The number smaller than 1,000,000,000' })
    capacity: number = 0;

    @prop()
    primaryPhoneExt: string;

    @prop()
    address: string;
    @prop()
    country: string;

    private _tags: string = '';
    private _tagArray: string[] = [];
    
    @prop()
    set tagsList(value: string[]) {
        this._tagArray = value;
        this._tags = value.join(',');
    }

    get tagsList(): string[] {
        return this._tagArray;
    }

    @prop()
    set tags(value: string) {
        this._tags = value;
        this._tagArray = value ? value.split(',').map(tag => tag.trim()) : [];
    }

    get tags(): string {
        return this._tags;
    }

    static contactToLeadModel(contact: ContactForm): SaleLeadsViewModel {
        let newSaleLead = new SaleLeadsViewModel();
        if (contact == null) return newSaleLead;
        newSaleLead.saluation = contact.saluation;
        newSaleLead.firstName = contact.contactName;
        newSaleLead.lastName = contact.contactLastName;
        newSaleLead.company = contact.relationship;
        newSaleLead.contactPhone = contact.contactPhone;
        newSaleLead.contactEmail = contact.contactEmail;
        newSaleLead.state = contact.state;
        newSaleLead.city = contact.city;
        newSaleLead.description = contact.description;
        newSaleLead.leadSource = contact.source;
        newSaleLead.website = contact.website;
        newSaleLead.department = contact.department;
        newSaleLead.typeName = ProfileType[ProfileType.LEADS];
        return newSaleLead;
    }
}

export interface SaleConvertHistory {
    saleCOnvertHistoryId: number;
    orgLeadId: string;

    toAccountId: string;
    toOpportunityId: string;
    toContactId: number;

    isNewAccount: boolean;
    isNewOpportunity: boolean;
    isNewContact: boolean;

    archived: boolean;

    deleted: boolean;
    dateDeleted: Date | string | null;
    dateCreated: Date | string | null;
    dateModified: Date | string | null;

    saleAccount: ProfileDetailModel;
    saleContact: ContactConvert;
    saleOpportunityProfile: ProfileDetailModel;

}

class ContactConvert extends Contact {
    dateCreated: string | null;
}

export class RecommendSaleLeadConvert {
    recommendContacts: number;
    contacts: Contact[];
    recommendSaleAccounts: number;
    saleAccounts: ProfileDetailModel[];
}