import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'calculateWorkingActions'
})
export class CalculateWorkingActionsPipe implements PipeTransform {
  transform(totalItems: number, selectedNumber: number): number {
    if (!totalItems || !selectedNumber) return 0;

    // If selectedNumber is greater than totalItems, return totalItems
    if (selectedNumber > totalItems) {
      return totalItems;
    }

    // Return number of items that will be worked on
    return selectedNumber;
  }
}
