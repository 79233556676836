import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'formatTags'
})
export class FormatTagsPipe implements PipeTransform {
  transform(value: string): string {
    if (!value) return '';
    
    // Split by comma and trim each tag
    return value.split(',')
      .map(tag => tag.trim())
      .filter(tag => tag.length > 0)
      .join(', ');
  }
} 