import { DataState } from 'src/app/shared/contances/primas-data-state';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, observable, Observable, ReplaySubject, Subject } from 'rxjs';
import { Page } from 'src/app/shared/models/paging/page';
import { PagedData, PagedDataNearbyProfile } from 'src/app/shared/models/paging/paged-data';
import { ReturnResult } from 'src/app/shared/models/return-result';
import { apiUrl, environment, host } from 'src/environments/environment';
import { ActivityLogFilters, ListDataProfile, ListDataUser, ProfileChangeLocalBusinessModel, ProfileGeneralChangeModel, ProfileLocationViewModel, ProfileLogDetail, ProfileModel, ProfilePropViewModel } from './profile-model';
import { PagingResult } from '../../../shared/models/paging-result';
import { first, timeout } from 'rxjs/operators';
import { tap } from 'rxjs/operators';
import { ProfileDetailModel, ProfileSeo, RepliedEmailData, SeoInfo, ProfileSaveChangeModel, Reason, Contact, MergeProfileModel, ProfileContact, RecommendContactInfo, MaintainNoIndexWithNewSeoPrimary } from './profile-detail.model';
import { ProfileRequest } from 'src/app/shared/models/request/profile-request.model';
import { ReasonRequest } from 'src/app/shared/models/request/reason-request.model';
import { BioRewriteViewModel, RelationCategoryViewModel, RemoveActionList } from './profile-detail/bio-n-reason/bio-reason-category.model';
import { ActivityLog } from '../campaign-management/campaign-tracking.model';
import { DataStates } from 'src/app/shared/models/data-state.model';
import { BulkEditModel } from './bulk-edit-profile/bulk-edit.model';
import { NextOrPrevious } from 'src/app/shared/components/stand-alone-component/next-or-previous-detail/next-previous.model';
import { ReturnDataField } from './profile-detail/automate-datastate-log/automate-datastate-model';
import { AutomateDatastateService } from './profile-detail/automate-datastate-log/automate-datastate.service';
import { ObjectChangedModel, OnBoardingFormViewModel } from 'src/app/shared/components/onboarding-form/onboarding-form-profile.model';
import { PagingSearchLocation, SearchLocationRequestPaging } from './grid-nearby-profile/paging-search-location';
import { ResponseUsMap } from '../dashboard-contact-map/dashboard-contact-map.component';
import { ListDataWpDetail } from '../wp-homepage-management/wp-home-page.model';
import { FFCUrl } from '../indexing/indexing.model';
import { ProposalArtists } from '../opportunity-management/opportunity.model';
import { BulkUpdateConsentModel } from './bulk-update-consent/bulk-update-consent.model';
import { RVRevisionMeta } from '../revision-version-group-management/revision-version.model';
import { ProfileVendorModel } from '../opportunity-management/opportunity-details/profile-vendor/profile-vendor.model';
@Injectable({
  providedIn: 'root'
})
export class ProfileService {
  baseUrl = environment.apiUser;
  profileUrl = environment.apiProfileManagement;
  reasonUrl = environment.reasonManagement;
  seoUrl = environment.seoInfoManagement;
  wpUrl = environment.apiFontFacing;
  revisionUrl = environment.apiRevisionVersion;

  pagingResult$: BehaviorSubject<PagingResult>;
  isHiddenInternalData$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  categoryUrl = environment.apiCategory;
  _profileDetailData$: ReplaySubject<ReturnResult<ProfileDetailModel>> = new ReplaySubject<ReturnResult<ProfileDetailModel>>(1);
  //profileObservable = this.profileSubject.asObservable();
  _profileRelationship$: ReplaySubject<ReturnResult<ProfileDetailModel[]>> = new ReplaySubject<ReturnResult<ProfileDetailModel[]>>(1);
  private _seoInfo$: ReplaySubject<ReturnResult<ProfileSeo[]>> = new ReplaySubject<ReturnResult<ProfileSeo[]>>(1);
  public profileLogDetails$ = new BehaviorSubject<ProfileLogDetail>(undefined);
  dataState$ = new BehaviorSubject<DataStates[]>([]);
  pagingDataStateResult$: BehaviorSubject<PagingResult>;
  profileDataChange$: BehaviorSubject<ProfileSaveChangeModel> = new BehaviorSubject<ProfileSaveChangeModel>(new ProfileSaveChangeModel());
  isSaveAllChange$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  private _pageFilterProfile$: ReplaySubject<PagedData<ProfileModel>> = new ReplaySubject<PagedData<ProfileModel>>(1);
  seoInfoChange$: BehaviorSubject<SeoInfo[]> = new BehaviorSubject<SeoInfo[]>([]);
  contactChange$: BehaviorSubject<Contact[]> = new BehaviorSubject<Contact[]>([]);
  profileLoadingStatus$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  isAutomateDataState$: ReplaySubject<boolean> = new ReplaySubject<boolean>(1);
  defaultDataField$: ReplaySubject<ReturnResult<ReturnDataField>> = new ReplaySubject<ReturnResult<ReturnDataField>>(1);
  private _existingProfile$: ReplaySubject<ReturnResult<ProfileDetailModel[]>> = new ReplaySubject<ReturnResult<ProfileDetailModel[]>>(1);

  constructor(private http: HttpClient, private automateDataStateService: AutomateDatastateService) { }

  getIsHiddenInternalData(): Observable<boolean> {
    return this.isHiddenInternalData$.asObservable();
  }
  contactChange() {
    return this.contactChange$.asObservable();
  }
  profileDataChange() {
    return this.profileDataChange$.asObservable();
  }
  seoInfoChange() {
    return this.seoInfoChange$.asObservable();
  }
  isSaveAllChange() {
    return this.isSaveAllChange$.asObservable();
  }
  dataContactChange(data, prop: string, contactId: number) {
    let contactLst = this.contactChange$.getValue();
    let contactIndex = contactLst.findIndex(x => x.contactId == contactId);
    if (contactIndex >= 0) {
      if (!data.isRemove) {
        contactLst[contactIndex][prop] = data.data;
        this.isSaveAllChange$.next(true);
      }
      else {
        contactLst[contactIndex][prop] = null;
        let deepClone: Contact = JSON.parse(JSON.stringify(contactLst[contactIndex]));
        deepClone.contactId = undefined;
        if (this.isEmptyProperty(deepClone))
          contactLst.splice(contactIndex, 1);
      }
    }
    else {
      if (!data.isRemove) {
        let newContact = new Contact();
        newContact.contactId = contactId;
        newContact[prop] = data.data;
        contactLst.push(newContact);
      }
    }
    this.contactChange$.next(contactLst);
    this.updateSaveChangeState();
  }
  dataSeoChange(data, prop: string, seoId: number) {
    let seoInfo = this.seoInfoChange$.getValue();
    let seoIndex = seoInfo.findIndex(x => x.seoInfoId == seoId);
    if (seoIndex >= 0) {
      if (!data.isRemove) {
        seoInfo[seoIndex][prop] = data.data;
        this.isSaveAllChange$.next(true);
      }
      else {
        seoInfo[seoIndex][prop] = null;
        let deepClone: SeoInfo = JSON.parse(JSON.stringify(seoInfo[seoIndex]));
        deepClone.seoInfoId = undefined;
        if (this.isEmptyProperty(deepClone))
          seoInfo.splice(seoIndex, 1);
      }
    }
    else {
      if (!data.isRemove) {
        let newSeo = new SeoInfo();
        newSeo.seoInfoId = seoId;
        newSeo[prop] = data.data;
        seoInfo.push(newSeo);
      }
    }
    this.seoInfoChange$.next(seoInfo);
    this.updateSaveChangeState();
  }
  dataChange(data, prop: string) {
    var profileSaveChange = this.profileDataChange$.getValue();
    if (!data.isRemove) {
      profileSaveChange[prop] = data.data;
      this.isSaveAllChange$.next(true);
    }
    else {
      profileSaveChange[prop] = null;
    }
    this.profileDataChange$.next(profileSaveChange);
    this.updateSaveChangeState();
  }
  // priority flag is for update priority only
  dataChangeReason(data: ReasonRequest, isRemove: boolean, isPriority: boolean = false) {
    var profileSaveChange = this.profileDataChange$.getValue();
    if (profileSaveChange.reason == null) profileSaveChange.reason = []
    if (!isRemove) {
      const findReason = profileSaveChange.reason.findIndex(x => x.reasonId == data.reasonId);
      if (findReason >= 0) {
        if (isPriority) {
          profileSaveChange.reason[findReason].priority = data.priority;
          if (!profileSaveChange.reason[findReason].reason1) {
            profileSaveChange.reason[findReason].reason1 = data.reason1;
          }
        } else {
          profileSaveChange.reason[findReason].reason1 = data.reason1;
        }
      }
      else {
        profileSaveChange.reason.push(data);
      }

      this.isSaveAllChange$.next(true);
    }
    else {
      const findReason = profileSaveChange.reason.findIndex(x => x.reasonId == data.reasonId);
      if (findReason >= 0)
        profileSaveChange.reason.splice(findReason, 1);
      if (profileSaveChange.reason.length == 0)
        profileSaveChange.reason = null;
    }
    this.profileDataChange$.next(profileSaveChange);
    this.updateSaveChangeState()
  }

  dataChangeBio(data: BioRewriteViewModel, isRemove: boolean, isPriority: boolean = false) {
    var profileSaveChange = this.profileDataChange$.getValue();
    if (profileSaveChange.bios == null) profileSaveChange.bios = []
    if (!isRemove) {
      const findBio = profileSaveChange.bios.findIndex(x => x.id == data.id);
      if (findBio >= 0) {
        if (isPriority) {
          profileSaveChange.bios[findBio].priority = data.priority;
          if (!profileSaveChange.bios[findBio].content) {
            profileSaveChange.bios[findBio].content = data.content;
          }

        } else {
          profileSaveChange.bios[findBio].content = data.content;
          profileSaveChange.bios[findBio].priority = data.priority ?? profileSaveChange.bios[findBio].priority;
        }
      }
      else {
        profileSaveChange.bios.push(data);
      }
      this.isSaveAllChange$.next(true);
    }
    else {
      const findBio = profileSaveChange.bios.findIndex(x => x.id == data.id);
      if (findBio >= 0)
        profileSaveChange.bios.splice(findBio, 1)
      if (profileSaveChange.bios.length == 0)
        profileSaveChange.bios = null;
    }
    this.profileDataChange$.next(profileSaveChange);
    this.updateSaveChangeState()
  }

  updateSaveChangeState() {
    var profileSaveChange = this.profileDataChange$.getValue()
    const isEmpty = this.isEmptyProperty(profileSaveChange);
    if (isEmpty && this.isSeoEmpty() && this.isContactEmpty())
      this.isSaveAllChange$.next(false)
    else this.isSaveAllChange$.next(true);
  }

  isSeoEmpty() {
    var seoInfoChange = this.seoInfoChange$.getValue();
    if (seoInfoChange.length == 0) return true;
    seoInfoChange.forEach(e => {
      if (this.isEmptyProperty(e)) {
        return true;
      }
    })
    return false;
  }
  isContactEmpty() {
    var contactLst = this.contactChange$.getValue()
    if (contactLst.length == 0) return true;
    contactLst.forEach(e => {
      if (this.isEmptyProperty(e)) {
        return true;
      }
    })
    return false;
  }
  isEmptyProperty(obj): boolean {
    return Object.values(obj).every(value => {
      if (value === null || value === undefined || value == '') {
        return true;
      }
      else
        return false;
    });
  }
  resetProfileDataChange() {
    this.profileDataChange$.next(new ProfileSaveChangeModel());
    this.updateSaveChangeState();
  }
  resetSEODataChange() {
    this.seoInfoChange$.next([]);
    this.updateSaveChangeState();
  }
  resetContactDataChange() {
    this.contactChange$.next([]);
    this.updateSaveChangeState();
  }
  toggleHiddenInternalData() {
    const isHiddenInternal = this.isHiddenInternalData$.getValue();
    this.isHiddenInternalData$.next(!isHiddenInternal);
  }

  getDataStatePaging(): Observable<PagingResult> {
    return this.pagingDataStateResult$.asObservable();
  }
  getPaging(): Observable<PagingResult> {
    return this.pagingResult$.asObservable();
  }
  getProfilePaging(page, isCustomView: boolean = false): Observable<ReturnResult<PagedData<ProfileModel>>> {
    page.isCustomView = isCustomView;
    return this.http.post<ReturnResult<PagedData<ProfileModel>>>(`${this.profileUrl}/get`,
      page);
  }
  getProfileById(): Observable<ReturnResult<ProfileDetailModel>> {
    // return the subject here
    // subscribers will will notified when the data is refreshed
    return this._profileDetailData$.asObservable();
  }
  setLoadingStatus(data: boolean = false) {
    this.profileLoadingStatus$.next(data);
  }
  getIsLoading() {
    return this.profileLoadingStatus$.asObservable();
  }
  refreshData(id: any): Observable<ReturnResult<ProfileDetailModel>> {  //call api
    this.isAutomateDataState$.asObservable().pipe(first()).subscribe(resp => {
      // console.log(`is auto data state ${id}`)
      if (resp) this.automateDataStateService.requestIsSatisfiedDataState(id);
    });

    return this.http.get<ReturnResult<ProfileDetailModel>>(`${this.profileUrl}/id?id=${id}`).pipe(tap(response => {
      this._profileDetailData$.next(response);
      this.profileLoadingStatus$.next(false);
    }));
  }
  getProfileByProfileId(id: string) {
    return this.http.get<ReturnResult<ProfileDetailModel>>(`${this.profileUrl}/id?id=${id}`);
  }
  // refreshData(): Observable<ReturnResult<ProfileDetailModel>> {
  //   return this._profileDetailData$.asObservable();
  // }
  createProfile(model: ProfileModel): Observable<ReturnResult<ProfileModel>> {
    return this.http.post<ReturnResult<ProfileModel>>(this.profileUrl, model);
  }
  exportProfile(page: Page): Observable<ReturnResult<any>> {
    return this.http.post<ReturnResult<any>>(`${this.profileUrl}/export`, page);
  }
  importProfile(formData: FormData): Observable<ReturnResult<any>> {
    return this.http.post<ReturnResult<any>>(`${this.profileUrl}/import`, formData).pipe(
      timeout(1200000)
    );
  }
  editProfile(id: string, profileRequest: ProfileRequest) {
    return this.http.post<ReturnResult<boolean>>(`${this.profileUrl}/update/${id}`, profileRequest);
  }
  editReason(reason: ReasonRequest) {
    return this.http.post<ReturnResult<boolean>>(`${this.reasonUrl}/update`, reason);
  }
  addReason(reason: ReasonRequest) {
    return this.http.post<ReturnResult<boolean>>(`${this.reasonUrl}/create`, reason);
  }
  editSeoInfo(seoInfo: SeoInfo, profileId: string) {
    return this.http.post<ReturnResult<boolean>>(`${this.seoUrl}/update/${profileId}`, seoInfo);
  }
  editBioRewrite(id: string, content: string, bioId: number, priority: number = null) {
    let bioRewriteViewModel: BioRewriteViewModel = {};
    bioRewriteViewModel.content = content;
    bioRewriteViewModel.id = bioId;
    bioRewriteViewModel.priority = priority;
    return this.http.post<ReturnResult<boolean>>(`${this.profileUrl}/EditBioRewrite/${id}`, bioRewriteViewModel);
  }
  // 2021-13-09 Vuongle add start
  unLinkProfile(profile: ProfileDetailModel, table: string, id: string) {
    return this.http.post(`${this.profileUrl}/UnlinkProfile`, {
      profile, id, table
    });
  }
  refreshProfileRelationship(profileId: string): Observable<ReturnResult<ProfileDetailModel[]>> {
    return this.http.get<ReturnResult<ProfileDetailModel[]>>(`${this.profileUrl}/ProfileRelationship/${profileId}`).pipe(tap(response => {
      this._profileRelationship$.next(response);
    }));
  }
  // 2021-13-09 Vuongle end start
  getProfileRelationship(): Observable<ReturnResult<ProfileDetailModel[]>> {
    return this._profileRelationship$.asObservable();
  }
  addProfileRelationship(profileId: string, profileRelationshipId: string) {
    return this.http.post(`${this.profileUrl}/ProfileRelationship`, {
      profileId, profileRelationshipId
    });
  }
  unLinkProfileRelationship(profileId: string) {
    return this.http.post(`${this.profileUrl}/RelationshipProfileUnlink?profileId=${profileId}`,
      profileId
    );
  }
  refreshSeoInfo(profileId: string) {
    return this.http.get(`${this.seoUrl}/${profileId}`).subscribe((res: ReturnResult<ProfileSeo[]>) => this._seoInfo$.next(res));
  }
  getSeoInfo(): Observable<ReturnResult<ProfileSeo[]>> {
    return this._seoInfo$.asObservable();
  }

  getDataStateDashboard(): Observable<ReturnResult<any>> {
    return this.http.get<ReturnResult<any>>(`${this.profileUrl}/DashboardDataState`);
  }
  deleteSeoInfo(seoInfoId: number) {
    return this.http.delete<ReturnResult<boolean>>(`${this.seoUrl}/DeleteSeo/${seoInfoId}`);
  }
  getFriendlyUniqueUrl(friendlyUrl: string) {
    const bodyFormData = new FormData();
    bodyFormData.append('friendlyUrl', friendlyUrl);
    return this.http.post<ReturnResult<string>>(`${this.seoUrl}/FindUniqueUrl`, bodyFormData);
  }
  setPrimary(profileId: string, profileRelationshipId: any) {
    return this.http.post<ReturnResult<boolean>>(`${this.seoUrl}/SetPrimary`, { profileId, profileRelationshipId });
  }
  addSeoCategory(relationCategory: RelationCategoryViewModel) {
    return this.http.post<ReturnResult<boolean>>(`${this.categoryUrl}/AddSeoCategory`, relationCategory);
  }
  removeSeoCategory(relationCategory: RelationCategoryViewModel) {
    return this.http.post<ReturnResult<boolean>>(`${this.categoryUrl}/DeleteSeoCategory`, relationCategory);
  }
  deleteProfile(profileId: string, isDisconnectCRM: boolean = false) {
    return this.http.delete<ReturnResult<boolean>>(`${this.profileUrl}/DeleteProfileAsync/${profileId}?isDisconnectCRM=${isDisconnectCRM}`);
  }
  deleteProfilesAsync(profileIds: string[], isDellAll: boolean = false, typeName: string = null, isTemporary: boolean = false, isArchive: boolean = false) {
    return this.http.post<ReturnResult<boolean>>(`${this.profileUrl}/DeleteProfilesAsync?isDeletedAll=${isDellAll}&typeName=${typeName}&isTemporary=${isTemporary}&archive=${isArchive}`, profileIds);
  }
  refreshProfileLogByTask() {
    if (this.profileLogDetails$.getValue()) {
      this.http.post<ReturnResult<ProfileLogDetail>>(`${this.profileUrl}/ProfileLogDetails/`, this.profileLogDetails$.getValue().filterInfo).subscribe(resp => {
        if (resp.result) {
          this.profileLogDetails$.next(resp.result);
        }
      });
    }

  }
  refreshProfileLog(profileId: string, pageNumber: number, filters: ActivityLogFilters) {
    // const date = Date.UTC(d.getFullYear(), d.getMonth(), d.getDate());
    this.http.post<ReturnResult<ProfileLogDetail>>(`${this.profileUrl}/ProfileLogDetails/`, {
      profileId, pageNumber, filters
    }).subscribe(resp => {
      if (resp.result) {
        this.profileLogDetails$.next(resp.result);
      }
    });
  }
  getProfileLogDetails(): Observable<ProfileLogDetail> {
    return this.profileLogDetails$.asObservable();
  }
  initProfileLogDetails(): void {
    this.profileLogDetails$.next(null);
  }
  getRiderAtachment(filePath: string, outside?: boolean) {
    if (!outside) {
      return this.http.get(`${filePath}`, { responseType: 'blob' });
    } else {
      return this.http.get(`${filePath}`, { responseType: 'blob' });
    }
  }
  uploadRiderAttachment(profileId: string, file: any): Observable<ReturnResult<string>> {
    const bodyFormData = new FormData();
    bodyFormData.append('upload', file);
    return this.http.post<ReturnResult<string>>(`${this.profileUrl}/UploadRiderAttachment/${profileId}`, bodyFormData);
  }
  removeRiderFile(fileName: string): Observable<ReturnResult<boolean>> {
    return this.http.post<ReturnResult<boolean>>(`${this.profileUrl}/RemoveRiderFile`, { path: fileName });
  }
  //2022-04-06 toanpq add start
  GetProfileAttachById(profileId: string): Observable<ReturnResult<ProfileModel>> {
    return this.http.get<ReturnResult<ProfileModel>>(`${this.profileUrl}/GetProfileAttachById/${profileId}`);
  }
  //2022-04-06 toanpq add end

  getBasicInformationOfProfileByIdAsync(profileId: string) {
    return this.http.get<ReturnResult<ProfileDetailModel>>(`${this.profileUrl}/GetBasicInformationOfProfileByIdAsync/${profileId}`);
  }
  refreshDataState() {
    return this.http.get<ReturnResult<DataStates[]>>(`${this.profileUrl}/DataState`).subscribe(e => this.dataState$.next(e.result));
  }
  getAllDataState() {
    return this.http.get<ReturnResult<DataStates[]>>(`${this.profileUrl}/DataState`);
  }
  getDataState() {
    return this.dataState$.asObservable();
  }
  refreshDataStatePaging(page: Page): Observable<ReturnResult<PagedData<DataStates>>> {

    return this.http.post<ReturnResult<PagedData<DataStates>>>(`${this.profileUrl}/GetDataStatePaging`, page);
  }
  deleteDataState(id: string) {
    return this.http.delete<ReturnResult<DataStates[]>>(`${this.profileUrl}/DeleteDataState/${id}`,);
  }
  addEditDataState(data: DataStates) {
    return this.http.put<ReturnResult<DataStates[]>>(`${this.profileUrl}/AddEditDataState`, data);
  }
  deleteDataStates(ids: number[]) {
    return this.http.post<ReturnResult<DataStates[]>>(`${this.profileUrl}/DeleteDataStates`, ids);
  }
  getRepliedEmailData(activityId: number): Observable<ReturnResult<RepliedEmailData>> {
    return this.http.get<ReturnResult<RepliedEmailData>>(`${this.profileUrl}/GetRepliedEmailData/${activityId}`);
  }
  editBulk(model: BulkEditModel): Observable<ReturnResult<boolean>> {
    return this.http.post<ReturnResult<boolean>>(`${this.profileUrl}/EditBulk`, model);
  }
  removeReason(item: ReasonRequest) {
    const profileDataChange = this.profileDataChange$.getValue();
    if (profileDataChange.reason != null) {
      const findIndex = profileDataChange.reason.findIndex(x => x.reasonId == item.reasonId);
      if (findIndex >= 0)
        profileDataChange.reason.splice(findIndex, 1);
      if (profileDataChange.reason.length == 0) profileDataChange.reason = null;

    }
    this.profileDataChange$.next(profileDataChange);
    this.updateSaveChangeState();
  }
  removeBioRewrite(item: BioRewriteViewModel) {
    const profileDataChange = this.profileDataChange$.getValue();
    if (profileDataChange.bios != null) {
      const findIndex = profileDataChange.bios.findIndex(x => x.id == item.id);
      if (findIndex >= 0)
        profileDataChange.bios.splice(findIndex, 1);
      if (profileDataChange.bios.length == 0) profileDataChange.bios = null;

    }

    this.profileDataChange$.next(profileDataChange);
    this.updateSaveChangeState();

  }

  removeContact(item: Contact) {
    const contactChange = this.contactChange$.getValue();
    if (contactChange != null) {
      const findIndex = contactChange.findIndex(x => x.contactId == item.contactId);
      if (findIndex >= 0)
        contactChange.splice(findIndex, 1);
    }
    this.contactChange$.next(contactChange);
    this.updateSaveChangeState();

  }

  removeSeo(item: SeoInfo) {
    const seoInfo = this.seoInfoChange$.getValue();
    if (seoInfo.length > 0) {
      const findIndex = seoInfo.findIndex(x => x.seoInfoId == item.seoInfoId);
      if (findIndex >= 0)
        seoInfo.splice(findIndex, 1);
    }
    this.seoInfoChange$.next(seoInfo);
    this.updateSaveChangeState();
  }
  dupplicateProfile(profile: ProfileModel): Observable<ReturnResult<boolean>> {
    return this.http.post<ReturnResult<boolean>>(`${this.profileUrl}/DupplicateProfile`, profile);
  }
  duplicateProfileAndContact(profile: ProfileModel): Observable<ReturnResult<boolean>> {
    return this.http.post<ReturnResult<boolean>>(`${this.profileUrl}/DupplicateProfileAndContact`, profile);
  }
  //2022-03-23 vuonglqn add start
  savePageFilterToObs(pageFilter: PagedData<ProfileModel>) {
    if (pageFilter)
      this._pageFilterProfile$.next(JSON.parse(JSON.stringify(pageFilter)));
  }

  getPageFilterObs(): Observable<PagedData<ProfileModel>> {
    return this._pageFilterProfile$.asObservable();
  }

  nextOrPreProfile(model: NextOrPrevious, isNext: boolean): Observable<ReturnResult<any>> {
    model['isNext'] = isNext;
    return this.http.post<ReturnResult<any>>(`${this.profileUrl}/NextOrPreProfile`, model);
  }
  //2022-03-23 vuonglqn add end
  // 2022-03-24 tienlm add start
  getMasterActGroupId(actGroupId: string): Observable<ReturnResult<string>> {
    return this.http.get<ReturnResult<string>>(`${this.profileUrl}/GetMasterActGroupId/${actGroupId}`);
  }

  //2022-03-25 vuonglqn add start
  resetIsMasterActGroup(actGroupId: string): Observable<ReturnResult<boolean>> {
    return this.http.post<ReturnResult<boolean>>(`${this.profileUrl}/ResetIsMasterActGroup?actGroupId=${actGroupId}`, {})
  }
  //2022-03-25 vuonglqn add end
  getProfileByIdObservable(id: any): Observable<ReturnResult<MergeProfileModel>> {  //call api
    return this.http.get<ReturnResult<MergeProfileModel>>(`${this.profileUrl}/id?id=${id}`);
  }
  mergeNewProfile(profile: any): Observable<ReturnResult<boolean>> {
    return this.http.post<ReturnResult<boolean>>(`${this.profileUrl}/MergeNewProfile`, profile);
  }

  //2022-03-29 vuonglqn add start
  getProfileIdOnce(id: any): Observable<ReturnResult<ProfileDetailModel>> {  //call api
    return this.http.get<ReturnResult<ProfileDetailModel>>(`${this.profileUrl}/id?id=${id}`);
  }
  //2022-03-29 vuonglqn add end

  getDataFieldProfile(): Observable<ReturnResult<ReturnDataField>> {
    return this.defaultDataField$.asObservable();
  }

  requestDataFieldProfile(profileId: string) {
    this.http.get<ReturnResult<ReturnDataField>>(`${this.profileUrl}/DataFieldProfile?profileId=${profileId}`).pipe(first()).subscribe(resp => {
      if (resp.result) this.defaultDataField$.next(resp);
    })
  }

  requestDataFieldProfileAsync(profileId: string): Observable<ReturnResult<ReturnDataField>> {
    return this.http.get<ReturnResult<ReturnDataField>>(`${this.profileUrl}/DataFieldProfile?profileId=${profileId}`);
  }
  // 2022-05-17 gnguyen start add
  saveExtendDataProfile(id: any, extendData: string): Observable<ReturnResult<boolean>> {

    return this.http.post<ReturnResult<boolean>>(`${this.profileUrl}/UpdateExtendDataProfile`, {
      profileId: id, extendData: extendData
    });
  }
  // 2022-05-17 gnguyen end add
  checkDuplicateDisplayName(displayName: string) {
    return this.http.get<ReturnResult<boolean>>(`${this.profileUrl}/CheckDuplicateDisplayName?displayName=${displayName}`);
  }
  getProfileToken(pid: string): Observable<OnBoardingFormViewModel> {
    return this.http.get<OnBoardingFormViewModel>(`${this.profileUrl}/profiletoken/${pid}`);
  }
  signupArtists(model: OnBoardingFormViewModel): Observable<ReturnResult<boolean>> {
    return this.http.post<ReturnResult<boolean>>(`${this.profileUrl}/Actsignup`, model);
  }
  artistUpdate(models: ObjectChangedModel[]): Observable<ReturnResult<boolean>> {
    return this.http.put<ReturnResult<boolean>>(`${this.profileUrl}/Artistupdate/Bulk`, models);
  }

  uploadRiderAttachmentOnboardingForm(file: File): Observable<ReturnResult<string>> {
    const bodyFormData = new FormData();
    bodyFormData.append('upload', file);
    return this.http.post<ReturnResult<string>>(`${this.profileUrl}/OnboardingForm/UploadRiderAttachment`, bodyFormData);
  }
  getInboxEmailData(activityId: number): Observable<ReturnResult<RepliedEmailData>> {
    return this.http.get<ReturnResult<RepliedEmailData>>(`${this.profileUrl}/GetInboxEmailData/${activityId}`);
  }
  changeGenderAsync(changes: ProfileGeneralChangeModel) {
    return this.http.post<ReturnResult<boolean>>(`${this.profileUrl}/ChangeGenderAsync`, changes);
  }
  ChangeIsVirtualActAsync(changes: ProfileGeneralChangeModel) {
    return this.http.post<ReturnResult<boolean>>(`${this.profileUrl}/ChangeIsVirtualActAsync`, changes);
  }
  ChangeOfficiateWeddingAsync(changes: ProfileGeneralChangeModel) {
    return this.http.post<ReturnResult<boolean>>(`${this.profileUrl}/ChangeOfficiateWeddingAsync`, changes);
  }

  LookupProfileByLocationPaging(page: PagingSearchLocation): Observable<ReturnResult<PagedDataNearbyProfile<ProfileLocationViewModel>>> {
    return this.http.post<ReturnResult<PagedDataNearbyProfile<ProfileLocationViewModel>>>(`${this.profileUrl}/LookupProfileByLocation`, page);
  }
  updateArchiveState(pid: string) {
    return this.http.post<ReturnResult<boolean>>(`${this.profileUrl}/UpdateArchiveState/${pid}`, {});
  }
  //2022-08-22 thoainna add start
  temporaryProfilePaging(page: Page): Observable<ReturnResult<PagedData<ProfileModel>>> {
    return this.http.post<ReturnResult<PagedData<ProfileModel>>>(`${this.profileUrl}/TemporaryProfilePaging`, page);
  }
  exportTemporaryProfile(page: Page): Observable<ReturnResult<any>> {
    return this.http.post<ReturnResult<any>>(`${this.profileUrl}/ExportTemporaryProfileAsync`, page);
  }

  getArchiveProfilePaging(page: Page): Observable<ReturnResult<PagedData<ProfileModel>>> {
    return this.http.post<ReturnResult<PagedData<ProfileModel>>>(`${this.profileUrl}/GetArchiveProfilePaging`, page);
  }
  exportArchiveProfile(page: Page): Observable<ReturnResult<any>> {
    return this.http.post<ReturnResult<any>>(`${this.profileUrl}/ExportArchiveProfileAsync`, page);
  }
  temporaryConvertToProfiles(pid: string) {
    return this.http.post<ReturnResult<boolean>>(`${this.profileUrl}/TemporaryConvertToProfiles/${pid}`, {});
  }

  getSearchProfileList() {
    return this._existingProfile$.asObservable();
  }

  searchProfile(keyword: string) {
    return this.http.get<ReturnResult<ProfileDetailModel[]>>(`${this.profileUrl}/Search?keyword=${keyword}`)
      .pipe(tap(res => this._existingProfile$.next(res)));
  }

  deleteProfileByFilter(model) {
    return this.http.post<ReturnResult<boolean>>(`${this.profileUrl}/DeleteAllAsync`, model);
  }

  GetCommunicationLogUnsubscribed(objId: string = "") {
    return this.http.get<ReturnResult<string[]>>(`${this.profileUrl}/GetCommunicationLogUnsubscribed?objId=${objId ?? ''}`);
  }

  bulkAddTag(page: Page, tags: string): Observable<ReturnResult<number>> {
    return this.http.post<ReturnResult<number>>(`${this.profileUrl}/BulkAddTags?tags=${tags}`, page);
  }

  mapPagingFilter(page: any): Observable<ReturnResult<PagedData<ProfileContact>>> {
    return this.http.post<ReturnResult<PagedData<ProfileContact>>>(`${this.profileUrl}/MapPagingFilter`, page);
  }

  statusActivedUsState(type: string[]): Observable<ReturnResult<any>> {
    return this.http.post<ReturnResult<any>>(`${this.profileUrl}/StatusActivedUsState`, type);
  }

  getSaleContactMap(): Observable<ReturnResult<any[]>> {
    return this.http.get<ReturnResult<any[]>>(`${this.profileUrl}/GetSaleContactMap`);
  }

  lastContactUsState(type: string[], accountFilter: any): Observable<ReturnResult<ResponseUsMap[]>> {
    return this.http.post<ReturnResult<any>>(`${this.profileUrl}/LastContactUsState`, { type, accountFilter });
  }
  importContactWithRelationship(formData: FormData, profileId: string): Observable<ReturnResult<any>> {
    return this.http.post<ReturnResult<any>>(`${this.profileUrl}/ImportByRelationship/${profileId}`, formData).pipe(
      timeout(1200000)
    );
  }
  saveContact(contactList: [], action: string): Observable<ReturnResult<any>> {
    return this.http.post<ReturnResult<any>>(`${this.profileUrl}/UpdateExistingContact`, { contactList, action });
  }
  saveSaleProcessEntity(contact: [], typeName): Observable<ReturnResult<any>> {
    return this.http.post<ReturnResult<any>>(`${this.profileUrl}/CreateSaleProcess`, { contactLst: contact, typeName });
  }
  getAllEmailOrPhoneByProfileId(id: string, typeObj: string, type: string = ""): Observable<ReturnResult<RecommendContactInfo[]>> {
    return this.http.get<ReturnResult<RecommendContactInfo[]>>(`${this.profileUrl}/GetAllEmailOrPhoneByProfileId?id=${id}&typeObj=${typeObj}&type=${type}`);
  }
  // exportActivityLog(model: ActivityLog[]): Observable<ReturnResult<any>> {
  //   return this.http.post<ReturnResult<any>>(`${this.profileUrl}/ExportActivityLog`, model);
  // }
  exportActivityLog(profileId: string, filters: ActivityLogFilters): Observable<ReturnResult<any>> {
    return this.http.post<ReturnResult<any>>(`${this.profileUrl}/ExportActivityLog`, { profileId, filters });
  }
  getListPropByListProfileId(listDataProfile: ListDataProfile[]): Observable<ReturnResult<ListDataProfile[]>> {
    return this.http.post<ReturnResult<ListDataProfile[]>>(`${this.profileUrl}/GetListPropByListProfileId`, listDataProfile);
  }
  getListPropByListUserId(listDataUser: ListDataUser[]): Observable<ReturnResult<ListDataUser[]>> {
    return this.http.post<ReturnResult<ListDataUser[]>>(`${this.profileUrl}/GetListPropByListUserId`, listDataUser);
  }
  getListPropByTypeId(listDataWpDetail: ListDataWpDetail[]): Observable<ReturnResult<ListDataWpDetail[]>> {
    return this.http.post<ReturnResult<ListDataWpDetail[]>>(`${this.wpUrl}/GetListPropByTypeId`, listDataWpDetail);
  }
  searchProposalArtist(keyword: string, typeName = '') {
    return this.http.get<ReturnResult<ProfileDetailModel[]>>(`${this.profileUrl}/SearchProposalArtist?keyword=${keyword}&typeName=${typeName ?? ''}`)
  }
  getProfileByIdLite(id: string) {
    return this.http.get<ReturnResult<ProfileDetailModel>>(`${this.profileUrl}/GetProfileByIdLite/${id}`)
  }
  bindingFfcUrl(model: ProfileSeo): Observable<ReturnResult<FFCUrl>> {
    return this.http.post<ReturnResult<FFCUrl>>(`${this.seoUrl}/BindingFfcUrl`, model)
  }
  getSeoInfoByProfileId(profileId: string, paged: Page): Observable<ReturnResult<PagedData<ProfileSeo>>> {
    return this.http.post<ReturnResult<PagedData<ProfileSeo>>>(`${this.seoUrl}/GetProfileSeoByProfileId/${profileId}`, paged);
  }
  getProposalByArtistId(page: Page, id: string): Observable<ReturnResult<PagedData<any>>> {
    return this.http.post<ReturnResult<PagedData<any>>>(`${this.profileUrl}/GetProposalByArtistId/${id}`, page);
  }

  bulkLinkRelationship(page: Page): Observable<ReturnResult<boolean>> {
    return this.http.post<ReturnResult<boolean>>(`${this.profileUrl}/GroupProfileRelationships`, page);
  }
  ChangeStatusShowLocalBusiness(changes: ProfileChangeLocalBusinessModel) {
    return this.http.post<ReturnResult<boolean>>(`${this.profileUrl}/ChangeStatusShowLocalBusiness`, changes);
  }

  getActivityLogPaging(id: string, pageNumber: number, filters: ActivityLogFilters): Observable<ReturnResult<ProfileLogDetail>> {
    return this.http.post<ReturnResult<ProfileLogDetail>>(`${this.profileUrl}/ProfileLogDetails/`, { profileId: id, pageNumber, filters })
      .pipe(tap(resp => { if (resp.result) this.profileLogDetails$.next(resp.result) }))
  }

  cleanAlgoliaCacheData(value: string): Observable<ReturnResult<boolean>> {
    return this.http.post<ReturnResult<boolean>>(`${this.profileUrl}/CleanAlgoliaCache`, { keyword: value });
  }

  updateConsentBulk(model: BulkUpdateConsentModel): Observable<ReturnResult<boolean>> {
    return this.http.post<ReturnResult<boolean>>(`${this.profileUrl}/UpdateProfileConsent`, model);
  }

  updateProfileLockStatus(data): Observable<ReturnResult<boolean>> {
    let dataRequest = {
      profileId: data?.profileId,
      isLocked: data?.isLocked,
    }
    return this.http.post<ReturnResult<boolean>>(`${this.profileUrl}/UpdateProfileLockStatus`, dataRequest);
  }
  maintainNoIndexWithNewSeoPrimary(oldSeoInfoId:number, newSeoInfoId: number = 0): Observable<ReturnResult<boolean>>{
    let data = new MaintainNoIndexWithNewSeoPrimary();
    data.oldSeoInfoId = oldSeoInfoId;
    data.newSeoInfoId = newSeoInfoId;
    return this.http.post<ReturnResult<boolean>>(`${this.seoUrl}/MaintainNoIndexWithNewSeoPrimary`, data);
  }
  submitRevision(profileId) {
    return this.http.put<ReturnResult<RVRevisionMeta>>(`${this.profileUrl}/SubmitRevision/${profileId}`, {});
  }
  getPendingRevision(profileId) {
    return this.http.get<ReturnResult<RVRevisionMeta>>(`${this.profileUrl}/GetPendingRevision/${profileId}`, {});
  }
  getPublishedRevision(id) {
    return this.http.get<ReturnResult<RVRevisionMeta>>(`${this.profileUrl}/GetPublishedRevision/${id}`);
  }
  removeRevision(revision) {
    return this.http.delete<ReturnResult<boolean>>(`${this.profileUrl}/RemoveRevision/${revision}`);
  }
  getLatestRejectRevisionById(profileId: string, type: string = "Profile") {
    return this.http.post<ReturnResult<RVRevisionMeta>>(`${this.revisionUrl}/GetLatestRejectRevisionById`, {
      objectId: profileId,
      objectType: type
    });
  }
  getProfileVendorPagingByProfileId(page: Page, profileId: string): Observable<ReturnResult<PagedData<any>>> {
    return this.http.post<ReturnResult<PagedData<any>>>(`${this.profileUrl}/GetProfileVendorPagingByProfileId/${profileId}`, page);
  }
  deleteProfileVendor(profileVendorId: number){
    return this.http.delete<ReturnResult<boolean>>(`${this.profileUrl}/DeleteProfileVendor/${profileVendorId}`);
  }
  updateProfileVendor(profileVendorModel: ProfileVendorModel){
    return this.http.post<ReturnResult<boolean>>(`${this.profileUrl}/UpdateProfileVendor`,profileVendorModel);
  }
  removePendingRevision(pid: String) {
    return this.http.delete<ReturnResult<boolean>>(`${this.profileUrl}/${pid}/Revision/Pending`);
  }

  getProposalByActGroupId(page: Page, id: string): Observable<ReturnResult<PagedData<any>>> {
    return this.http.post<ReturnResult<PagedData<any>>>(`${this.profileUrl}/GetProposalByActGroupId/${id}`, page);
  }
}
